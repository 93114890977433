.contact-page {
	.l-container-header {
		display: flex;
		justify-content: center;
		padding-top: 35px;
		padding-bottom: 60px;
		.logo {
			width: 102px;
			@include SP {
				width: 62px;
			}
			img {
				width: 100%;
			}
		}
		@include SP {
			justify-content: flex-start;
			padding-top: 25px;
			padding-left: (25 / 375 * 100 + 0%);
		}
	}

	.l-container-body {
		@include PC {
			display: flex;
			justify-content: center;
			align-items: center;
			height: calc(100vh - 288px);
		}


		.contact-btn {
			width: 270px;
			height: 60px;

			margin-left: 55px;
			cursor: pointer;
			border: 1px solid #000;
			@include PC {
				transition: opacity .3s ease;
				&:hover {
					opacity: 0.7;
				}
			}
			@include SP {
				border: 1px solid #000;
				width: 220px;
				height: 50px;
				font-size: 16px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 55px;
			}
			.link {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				width: 100%;
				height: 100%;
			}
		}

		.contents {
			text-align: right;

			.company-name {
				font-size: 19px;
				font-weight: 400;
				padding-right: 50px;
				margin-bottom: 10px;
				@include SP {
					margin-bottom: 10px;
					font-size: 18px;
					padding-right: (68 / 375 * 100 + 0%);
				}
			}
			.free {
				padding-right: 50px;
				padding-top: 40px;
				padding-bottom: 40px;
				line-height: 1.8;
				font-size: 16px;
				border-right: 1px solid rgba(0,0,0,0.25);
				@include SP {
					padding-top: 20px;
					padding-bottom: 20px;
					padding-right: (20 / 375 * 100 + 0%);
					padding-left: (20 / 375 * 100 + 0%);
					margin-right: (60 / 375 * 100 + 0%);
				}
			}
			.tel-mail {
				padding-right: 50px;
				margin-top: 10px;
				a {
					@include PC {
						&:hover {
							text-decoration: underline;
						}

					}
				}
				@include SP {
					padding-right: (80 / 375 * 100 + 0%);
				}
			}
		}
	}
}
