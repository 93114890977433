.detail-page {

	.l-container {
		position: relative;

		.logo {
			position: absolute;
			left: 0;
			right: 0;
			top: 35px;
			margin: auto;
			display: flex;
			justify-content: center;
			width: 102px;
			@include SP {
				width: 62px;
				right: auto;
				top: 25px;
				left: (25 / 375 * 100 + 0%);
				z-index: 2;
			}
			.link {
				display: block;
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
	}


	.l-container-body {
		display: flex;
		@include SP {
			flex-direction: column;
		}
	}

	.sidemenu {
		width: (270 / 1000 * 100 + 0%);
		position: fixed;
		left: 0;
		top: 0;
		padding-left: (40 / 1000 * 100 + 0%);
		background: #fff;
		padding-top: 135px;
		@include SP {
			position: relative;
			width: 100%;
			padding-left: (70 / 375 * 100 + 0%);
			padding-right: (70 / 375 * 100 + 0%);
			padding-top: 110px;
			padding-bottom: 100px;
		}
		.model-name {
			font-size: 42px;
			font-weight: 300;
			line-height: 1.4;
		}

		.pdf-insta {
			display: flex;
			align-items: flex-end;
			font-size: 13px;
			margin-top: 20px;
			font-weight: 600;
			.pdf {
				.link {
					display: flex;
					align-items: center;
					.icon {
						margin-left: 10px;
						position: relative;
						top: -5px;
					}
				}
			}
			.insta {
				width: 18px;
				margin-left: 20px;
				position: relative;
				top: -4px;
			}
		}

		.anchor-list {

			@include PC {
				margin-top: 30px;
				border-left: 1px solid #000;
				padding-left: 10px;
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 15px;
			}


			@include SP {
				font-size: 10px;
				display: flex;
				justify-content: center;
				position: absolute;
				right: 0;
				left: 0;
				bottom: 35px;
				margin: auto;
				&::before {
					content: '';
					display: block;
					width: (85 / 375 * 100 + 0%);
					height: 1px;
					background: rgba(0,0,0,0.25);
					position: absolute;
					right: 0;
					left: 0;
					bottom: calc(100% + 20px);
					margin: auto;
				}
			}
			.anchor-list-item {
				line-height: 1;
				cursor: pointer;
				font-weight: 300;
				&.is-active {
					font-weight: 400;
				}

				@include PC {
					&:not(:first-child) {
						margin-top: 15px;
					}
					&:hover {
						span {
							&::before {
								width: 100%;
							}
						}
					}
				}
				@include SP {
					&:not(:first-child) {
						margin-left: 20px;
					}
				}

				span {
					position: relative;
					&::before {
						content: '';
						display: block;
						width: 0;
						height: 1px;
						background: #000;
						position: absolute;
						left: 0;
						top: 100%;
						transition: width .3s ease;
					}
				}

			}
		}
		.model-datas {
			margin-top: 27px;
			font-size: 13px;
			display: flex;
			flex-wrap: wrap;
			.item {
				display: flex;
				flex-direction: column;
				margin-right: 20px;
				margin-bottom: 15px;
				.title {
					font-weight: 400;
				}
				.text {
					font-weight: 600;
				}
			}
		}
	}

	.detail-container {
		width: 100%;
		@include PC {
			padding-top: 135px;
			padding-right: (130 / 1000 * 100 + 0%);
			padding-left: (300 / 1000 * 100 + 0%);
		}


		.inner {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
			@include SP {
				padding-left: (15 / 375 * 100 + 0%);
				padding-right: (15 / 375 * 100 + 0%);
			}
		}

		.image {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			@include PC {
				margin-left: (10 / 570 * 100 + 0%);
				margin-right: (10 / 570 * 100 + 0%);
				margin-top: 20px;
			}
			@include SP {
				margin-top: (15 / 375 * 100 + 0%);
			}
			&.half {
				width: (265 / 570 * 100 + 0%);
				@include SP {
					width: 100%;
				}
				&::before {
					content: '';
					display: block;
					width: 100%;
					padding-top: (783 / 530 * 100 + 0%);
					@include SP {
						padding-top: (518 / 360 * 100 + 0%);
					}
				}
			}
			&.full {
				width: 100%;
				&::before {
					content: '';
					display: block;
					width: 100%;
					padding-top: (780 / 1100 * 100 + 0%);
				}
			}
		}



		.title {
			margin-left: (10 / 570 * 100 + 0%);
			font-size: 15px;
			margin-top: 35px;
			@include SP {
				display: none;
			}
		}

		.video-block {
			width: 100%;
			background: #000;
			position: relative;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			cursor: pointer;
			@include PC {
				margin-top: 20px;
				margin-left: (10 / 570 * 100 + 0%);
				margin-right: (10 / 570 * 100 + 0%);
			}
			@include SP {
				margin-top: ( 15 / 375 * 100 + 0%);
			}
			&::before {
				content: '';
				display: block;
				width: 100%;
				padding-top: (310 / 550 * 100 + 0%);
			}
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}

		.column {
			@include SP {
				display: none;
				opacity: 0;

				&.is-active {
					display: block;
					animation: fadeIn .5s ease .1s forwards;
				}
			}
		}
	}
	.swiper-slide {
		display: flex;
		justify-content: center;
		height: auto;
		.img-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
		.wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		@include SP {
			img {
				width: 80%;
			}
			.wrap {
				iframe {
					width: auto;
					height: auto;
				}
			}
		}
	}
	.swiper-button-prev {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		@include PC {
			transition: transform .3s ease;
			&:hover {
				transform: translateX(-10px)
			}
		}
		@include SP {
			width: 55px;
			height: 20px;
			left: 0;
		}
		&::after {
			transform: scale(-1,1);
			width: 38px;
			height: 14px;
			background: url(../img/detail/arrow@2x.png) no-repeat center / cover;
			font-size: 0;
			@include SP {
				width: 55px;
				height: 20px;
				background-image: url(../img/detail/arrow.sp@2x.png);
			}
		}
	}
	.swiper-button-next {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		@include PC {
			transition: transform .3s ease;
			&:hover {
				transform: translateX(10px)
			}
		}
		@include SP {
			width: 55px;
			height: 20px;
			right: 0;
		}
		&::after {
			width: 38px;
			height: 14px;
			background: url(../img/detail/arrow@2x.png) no-repeat center / cover;
			font-size: 0;
			@include SP {
				width: 55px;
				height: 20px;
				background-image: url(../img/detail/arrow.sp@2x.png);
			}
		}
	}
}
