.list-page {
	padding-top: 35px;
	@include SP {
		padding-top: 25px;
	}
	.l-container-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;
		@include SP {
			align-items: flex-start;
			padding-bottom: 35px;
			position: relative;
		}
		.logo {
			width: 102px;
			@include SP {
				width: 62px;
				position: absolute;
				top: 0;
				left: (25 / 375 * 100 + 0%);
			}
			img {
				width: 100%;
			}
		}

		.select-list {
			display: flex;
			justify-content: center;
			margin-top: 95px;
			@include SP {
				width: 100%;
			}
			.select-list-item {
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				position: relative;
				font-weight: 300;
				&::before {
					content: '';
					display: block;
					width: 0;
					height: 1px;
					background: #000;
					position: absolute;
					left: 0;
					top: calc(3px + 100%);
					transition: width .3s ease;
				}
				&:not(:first-child) {
					margin-left: 70px;
					@include SP {
						margin-left: 25px;
					}
				}

				&.is-active {
					font-weight: 400;
				}

				@include PC {
					&:hover {
						&::before {
							width: 100%;
						}
					}
				}
			}
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	.l-container-body {
		.model-list {
			display: none;
			flex-wrap: wrap;
			max-width: 955px;
			margin: 0 auto;
			opacity: 0;
			&.is-active {
				display: flex;
				animation: fadeIn .5s ease .1s forwards;
			}

			@include SP {
				padding-left: (15 / 375 * 100 + 0%);
				padding-right: (15 / 375 * 100 + 0%);
			}
			.model-list-item {
				width: (220 / 955 * 100 + 0%);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				position: relative;
				&::before {
					content: '';
					display: block;
					width: 100%;
					padding-top: (325 / 220 * 100 + 0%);
					@include SP {
						padding-top: (220 / 155 * 100 + 0%);
					}
				}
				@include PC {
					&:not(:nth-child(4n+1)) {
						margin-left: (25 / 955 * 100 + 0%);
					}

					&:not(:nth-child(-n+4)) {
						margin-top: (25 / 955 * 100 + 0%);
					}
				}
				@include SP {
					width: (165 / 345 * 100 + 0%);
					&:not(:nth-child(2n+1)) {
						margin-left: (15 / 345 * 100 + 0%);
					}

					&:not(:nth-child(-n+2)) {
						margin-top: (15 / 345 * 100 + 0%);
					}
				}

				.new {
					font-size: 12px;
					position: absolute;
					bottom: calc(100% - 2px);
					left: 0;
					line-height: 1;
					@include SP {
						font-size: 10px;
					}
					&::before {
						content: '';
						display: block;
						width: 53px;
						height: 1px;
						background: #000;
						position: absolute;
						left: -5px;
						bottom: -3px;
						@include SP {
							width: 38px;
						}
					}
				}

				.link {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					display: flex;
					color: #fff;
					overflow: hidden;
					@include PC {
						&:hover {
							.black {
								&::before {
									left: -15%;
   									width: 140%;
								}
							}
							.text-wrapper {
								transition-delay: .2s;
								opacity: 1;
							}
							.plus-mark {
								transition-delay: .2s;
								opacity: 1;
							}
							.not-in-town {
								transition-delay: .2s;
								opacity: 1;
							}
						}
					}
					@include SP {
						&.is-hover {
							.black {
								&::before {
									left: -15%;
									   width: 140%;
								}
							}
							.text-wrapper {
								transition-delay: .2s;
								opacity: 1;
							}
							.plus-mark {
								transition-delay: .2s;
								opacity: 1;
							}
							.not-in-town {
								transition-delay: .2s;
								opacity: 1;
							}
						}
					}
				}

				.black {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					&::before {
						position: absolute;
						transition: .3s;
						content: '';
						width: 0;
						left: -15%;
						bottom: 0;
						height: 120%;
						background: rgba(0,0,0,0.85);
					}
				}

				.text-wrapper {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-start;
					padding: (15 / 220 * 100 + 0%);
					opacity: 0;
					transition: opacity .3s ease;
					@include SP {
						padding: (15 / 155 * 100 + 0%);
					}
					.name {
						font-size: 25px;
						margin-bottom: 30px;
						@include SP {
							font-size: 15px;
							margin-bottom: 15px;
						}
					}

					.info-list {
						width: 100%;
						.info-list-item {
							font-size: 10px;
							display: flex;
							&:not(:first-child) {
								margin-top: 6px;
								@include SP {
									margin-top: 5px;
								}
							}

							.info-name {
								font-weight: 400;
							}

							.data {
								font-weight: 600;
								display: block;
								margin-left: (5 / 220 * 100 + 0%);
							}
						}
					}
				}

				.not-in-town {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0;
					transition: opacity .3s ease;
					.text {
						display: flex;
						align-items: flex-end;
						border-bottom: 1px solid #fff;
						.font-small {
							display: block;
							font-size: 10px;
							font-weight: 300;
							line-height: 1;
							padding-bottom: 4px;
							@include SP {
								font-size: 8px;
							}
						}
						.font-large {
							font-weight: bold;
							display: block;
							font-size: 15px;
							font-weight: normal;
							margin-left: 10px;
							line-height: 1;
							padding-bottom: 2px;
							@include SP {
								font-size: 12px;
							}
						}
					}
				}


				.plus-mark {
					width: 24px;
					height: 24px;
					position: absolute;
					right: (15 / 220 * 100 + 0%);
					bottom: (15 / 325 * 100 + 0%);
					opacity: 0;
					transition: opacity .3s ease;
					@include SP {
						width: 15px;
						height: 15px;
						right: (15 / 155 * 100 + 0%);
						bottom: (15 / 155 * 100 + 0%);
					}
					&::before {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						background: #fff;
						margin: auto;
					}
					&::after {
						content: '';
						display: block;
						width: 1px;
						height: 100%;
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						background: #fff;
						margin: auto;
					}
				}
			}
		}
	}

}
