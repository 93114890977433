@charset "UTF-8";
/**
 * フォントサイズの可変
 *
 * @param {number} $fontsize 基準となるフォントサイズ
 * @param {number} $basewidth 基準となる幅
 * @param {number} $maxwidth フォントサイズの拡大をとめる幅（未指定時は上限なし）
 * @param {number} $minwidth フォントサイズの縮小をとめる幅（未指定時は下限なし）
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, button, textarea, select, option {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

a, a:hover, a:visited, a:active, a:focus, a:focus-within {
  color: inherit;
}

ul {
  list-style: none;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

main {
  display: block;
}

@font-face {
  font-family: 'jost';
  src: url("./font/Jost-400-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url("./font/Jost-300-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url("./font/Jost-600-Semi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url("./font/Jost-700-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  font-weight: 400;
  color: #333;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'ＭＳ Ｐゴシック',sans-serif;
  }
}

.site-frame {
  overflow-x: hidden;
  opacity: 0;
}

.site-frame.active {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.jost {
  font-family: 'jost';
}

.bask {
  font-family: 'Libre Baskerville', serif;
}

.g-modal-frame {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2001;
  overflow: auto;
  transition: opacity .3s ease, visibility .3s ease;
  pointer-events: none;
}

.g-modal-frame.active {
  opacity: 1;
  visibility: visible;
}

.g-modal-frame .g-modal-table {
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.g-modal-frame .g-modal-cell {
  display: block;
  width: 100%;
  height: 100%;
}

.g-modal-frame .g-modal {
  pointer-events: none;
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .g-modal-frame .g-modal {
    padding-left: 5.33333%;
    padding-right: 5.33333%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.g-modal-frame .g-modal > .inner {
  pointer-events: auto;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 2;
  padding: 10px 0;
  position: relative;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .g-modal-frame .g-modal > .inner {
    padding: 10px 0;
  }
}

.g-modal-frame .g-modal > .inner img {
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .g-modal-frame .g-modal > .inner img {
    max-height: calc(100vh - 20px);
  }
}

.g-modal-frame .close-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
  transition: transform .5s ease;
}

@media screen and (min-width: 768px) {
  .g-modal-frame .close-btn:hover {
    transform: rotate(90deg);
  }
}

.g-modal-frame .close-btn .close-icon {
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
}

.g-modal-frame .close-btn .close-icon::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
}

.g-modal-frame .close-btn .close-icon::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-45deg);
}

.g-modal-frame .overlay {
  pointer-events: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 28, 0.85);
  z-index: 1;
}

.g-modal-frame.modal-image .inner {
  display: flex;
  justify-content: center;
}

.g-modal-frame.modal-youtube {
  display: none;
}

.g-modal-frame.modal-youtube .modal-youtube-inner {
  position: relative;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  pointer-events: auto;
  position: relative;
  z-index: 2;
}

.g-modal-frame.modal-youtube .modal-youtube-inner::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 55.55556%;
}

.footer {
  padding-top: 75px;
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .footer {
    padding-top: 65px;
    padding-bottom: 20px;
  }
}

.footer .footer-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
}

.footer .footer-inner .copyright {
  font-size: 10px;
  text-align: center;
}

.footer .footer-list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 10px;
}

.footer .footer-list .footer-list-item {
  line-height: 1;
}

.footer .footer-list .footer-list-item:not(:first-child) {
  margin-left: 13px;
  position: relative;
}

.footer .footer-list .footer-list-item:not(:first-child)::before {
  content: '';
  display: block;
  width: 1px;
  height: 8px;
  position: absolute;
  right: calc(100% + 6px);
  top: 0;
  bottom: 0;
  margin: auto;
  background: #000;
}

@media screen and (min-width: 768px) {
  .header {
    width: 300px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;
    transform: translateX(190px);
    transition: transform .5s ease;
    z-index: 10;
  }
}

@media screen and (min-width: 768px) {
  .header.is-hover {
    transform: translateX(0);
  }
  .header.is-hover .toggle .border::before {
    bottom: 0;
    transform: rotate(45deg);
  }
  .header.is-hover .toggle .border::after {
    top: 0;
    transform: rotate(-45deg);
  }
}

@media screen and (max-width: 767px) {
  .header.is-active .toggle .border::before {
    bottom: 0;
    transform: rotate(45deg);
  }
  .header.is-active .toggle .border::after {
    top: 0;
    transform: rotate(-45deg);
  }
  .header.is-active .global-nav {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (min-width: 768px) {
  .header .toggle {
    width: 110px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .header .toggle {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 6.66667%;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    pointer-events: auto;
  }
}

.header .toggle .border {
  height: 16px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header .toggle .border {
    width: 30px;
  }
}

@media screen and (max-width: 767px) {
  .header .toggle .border {
    width: 25px;
  }
}

.header .toggle .border::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform .5s ease;
}

.header .toggle .border::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: transform .5s ease;
}

@media screen and (max-width: 767px) {
  .header .global-nav-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    pointer-events: none;
  }
}

@media screen and (min-width: 768px) {
  .header .global-nav {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 30px;
    width: 190px;
  }
}

@media screen and (max-width: 767px) {
  .header .global-nav {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 12%;
    background: #fff;
    transition: opacity .5s ease, visibility .5s ease;
    pointer-events: auto;
  }
}

.header .global-nav .global-nav-item:not(:first-child) {
  margin-top: 25px;
}

.header .global-nav .link {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}

.header .global-nav .link span {
  position: relative;
}

.header .global-nav .link span::before {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 100%;
  transition: width .3s ease;
}

@media screen and (min-width: 768px) {
  .header .global-nav .link:hover span::before {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .header .global-nav .link {
    font-size: 27px;
  }
}

.contact-page .l-container-header {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 60px;
}

.contact-page .l-container-header .logo {
  width: 102px;
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-header .logo {
    width: 62px;
  }
}

.contact-page .l-container-header .logo img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-header {
    justify-content: flex-start;
    padding-top: 25px;
    padding-left: 6.66667%;
  }
}

@media screen and (min-width: 768px) {
  .contact-page .l-container-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 288px);
  }
}

.contact-page .l-container-body .contact-btn {
  width: 270px;
  height: 60px;
  margin-left: 55px;
  cursor: pointer;
  border: 1px solid #000;
}

@media screen and (min-width: 768px) {
  .contact-page .l-container-body .contact-btn {
    transition: opacity .3s ease;
  }
  .contact-page .l-container-body .contact-btn:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-body .contact-btn {
    border: 1px solid #000;
    width: 220px;
    height: 50px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 55px;
  }
}

.contact-page .l-container-body .contact-btn .link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.contact-page .l-container-body .contents {
  text-align: right;
}

.contact-page .l-container-body .contents .company-name {
  font-size: 19px;
  font-weight: 400;
  padding-right: 50px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-body .contents .company-name {
    margin-bottom: 10px;
    font-size: 18px;
    padding-right: 18.13333%;
  }
}

.contact-page .l-container-body .contents .free {
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 1.8;
  font-size: 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-body .contents .free {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 5.33333%;
    padding-left: 5.33333%;
    margin-right: 16%;
  }
}

.contact-page .l-container-body .contents .tel-mail {
  padding-right: 50px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .contact-page .l-container-body .contents .tel-mail a:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 767px) {
  .contact-page .l-container-body .contents .tel-mail {
    padding-right: 21.33333%;
  }
}

.detail-page .l-container {
  position: relative;
}

.detail-page .l-container .logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 102px;
}

@media screen and (max-width: 767px) {
  .detail-page .l-container .logo {
    width: 62px;
    right: auto;
    top: 25px;
    left: 6.66667%;
    z-index: 2;
  }
}

.detail-page .l-container .logo .link {
  display: block;
  width: 100%;
}

.detail-page .l-container .logo img {
  width: 100%;
}

.detail-page .l-container-body {
  display: flex;
}

@media screen and (max-width: 767px) {
  .detail-page .l-container-body {
    flex-direction: column;
  }
}

.detail-page .sidemenu {
  width: 27%;
  position: fixed;
  left: 0;
  top: 0;
  padding-left: 4%;
  background: #fff;
  padding-top: 135px;
}

@media screen and (max-width: 767px) {
  .detail-page .sidemenu {
    position: relative;
    width: 100%;
    padding-left: 18.66667%;
    padding-right: 18.66667%;
    padding-top: 110px;
    padding-bottom: 100px;
  }
}

.detail-page .sidemenu .model-name {
  font-size: 42px;
  font-weight: 300;
  line-height: 1.4;
}

.detail-page .sidemenu .pdf-insta {
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  margin-top: 20px;
  font-weight: 600;
}

.detail-page .sidemenu .pdf-insta .pdf .link {
  display: flex;
  align-items: center;
}

.detail-page .sidemenu .pdf-insta .pdf .link .icon {
  margin-left: 10px;
  position: relative;
  top: -5px;
}

.detail-page .sidemenu .pdf-insta .insta {
  width: 18px;
  margin-left: 20px;
  position: relative;
  top: -4px;
}

@media screen and (min-width: 768px) {
  .detail-page .sidemenu .anchor-list {
    margin-top: 30px;
    border-left: 1px solid #000;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .detail-page .sidemenu .anchor-list {
    font-size: 10px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 35px;
    margin: auto;
  }
  .detail-page .sidemenu .anchor-list::before {
    content: '';
    display: block;
    width: 22.66667%;
    height: 1px;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 0;
    left: 0;
    bottom: calc(100% + 20px);
    margin: auto;
  }
}

.detail-page .sidemenu .anchor-list .anchor-list-item {
  line-height: 1;
  cursor: pointer;
  font-weight: 300;
}

.detail-page .sidemenu .anchor-list .anchor-list-item.is-active {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .detail-page .sidemenu .anchor-list .anchor-list-item:not(:first-child) {
    margin-top: 15px;
  }
  .detail-page .sidemenu .anchor-list .anchor-list-item:hover span::before {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .detail-page .sidemenu .anchor-list .anchor-list-item:not(:first-child) {
    margin-left: 20px;
  }
}

.detail-page .sidemenu .anchor-list .anchor-list-item span {
  position: relative;
}

.detail-page .sidemenu .anchor-list .anchor-list-item span::before {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 100%;
  transition: width .3s ease;
}

.detail-page .sidemenu .model-datas {
  margin-top: 27px;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
}

.detail-page .sidemenu .model-datas .item {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 15px;
}

.detail-page .sidemenu .model-datas .item .title {
  font-weight: 400;
}

.detail-page .sidemenu .model-datas .item .text {
  font-weight: 600;
}

.detail-page .detail-container {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .detail-page .detail-container {
    padding-top: 135px;
    padding-right: 13%;
    padding-left: 30%;
  }
}

.detail-page .detail-container .inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .inner {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.detail-page .detail-container .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  .detail-page .detail-container .image {
    margin-left: 1.75439%;
    margin-right: 1.75439%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .image {
    margin-top: 4%;
  }
}

.detail-page .detail-container .image.half {
  width: 46.49123%;
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .image.half {
    width: 100%;
  }
}

.detail-page .detail-container .image.half::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 147.73585%;
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .image.half::before {
    padding-top: 143.88889%;
  }
}

.detail-page .detail-container .image.full {
  width: 100%;
}

.detail-page .detail-container .image.full::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 70.90909%;
}

.detail-page .detail-container .title {
  margin-left: 1.75439%;
  font-size: 15px;
  margin-top: 35px;
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .title {
    display: none;
  }
}

.detail-page .detail-container .video-block {
  width: 100%;
  background: #000;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .detail-page .detail-container .video-block {
    margin-top: 20px;
    margin-left: 1.75439%;
    margin-right: 1.75439%;
  }
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .video-block {
    margin-top: 4%;
  }
}

.detail-page .detail-container .video-block::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.36364%;
}

.detail-page .detail-container .video-block iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@media screen and (max-width: 767px) {
  .detail-page .detail-container .column {
    display: none;
    opacity: 0;
  }
  .detail-page .detail-container .column.is-active {
    display: block;
    animation: fadeIn .5s ease .1s forwards;
  }
}

.detail-page .swiper-slide {
  display: flex;
  justify-content: center;
  height: auto;
}

.detail-page .swiper-slide .img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.detail-page .swiper-slide .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .detail-page .swiper-slide img {
    width: 80%;
  }
  .detail-page .swiper-slide .wrap iframe {
    width: auto;
    height: auto;
  }
}

.detail-page .swiper-button-prev {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

@media screen and (min-width: 768px) {
  .detail-page .swiper-button-prev {
    transition: transform .3s ease;
  }
  .detail-page .swiper-button-prev:hover {
    transform: translateX(-10px);
  }
}

@media screen and (max-width: 767px) {
  .detail-page .swiper-button-prev {
    width: 55px;
    height: 20px;
    left: 0;
  }
}

.detail-page .swiper-button-prev::after {
  transform: scale(-1, 1);
  width: 38px;
  height: 14px;
  background: url(../img/detail/arrow@2x.png) no-repeat center/cover;
  font-size: 0;
}

@media screen and (max-width: 767px) {
  .detail-page .swiper-button-prev::after {
    width: 55px;
    height: 20px;
    background-image: url(../img/detail/arrow.sp@2x.png);
  }
}

.detail-page .swiper-button-next {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

@media screen and (min-width: 768px) {
  .detail-page .swiper-button-next {
    transition: transform .3s ease;
  }
  .detail-page .swiper-button-next:hover {
    transform: translateX(10px);
  }
}

@media screen and (max-width: 767px) {
  .detail-page .swiper-button-next {
    width: 55px;
    height: 20px;
    right: 0;
  }
}

.detail-page .swiper-button-next::after {
  width: 38px;
  height: 14px;
  background: url(../img/detail/arrow@2x.png) no-repeat center/cover;
  font-size: 0;
}

@media screen and (max-width: 767px) {
  .detail-page .swiper-button-next::after {
    width: 55px;
    height: 20px;
    background-image: url(../img/detail/arrow.sp@2x.png);
  }
}

@media screen and (min-width: 768px) {
  .index-page .l-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .index-page .l-container {
    padding-top: 17.33333%;
  }
}

.index-page .l-container-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .index-page .l-container-body {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
}

@media screen and (max-width: 767px) {
  .index-page .l-container-body {
    height: calc(100vh - 80px);
    position: relative;
  }
  .index-page .l-container-body _::-webkit-full-page-media, .index-page .l-container-body _:future, :root .index-page .l-container-body {
    height: -webkit-fill-available;
  }
}

@media screen and (max-width: 767px) {
  .index-page .l-container-body .logo svg {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .index-page .l-container-body .sex-list {
    margin-left: 7.8%;
  }
  .index-page .l-container-body .sex-list .item {
    margin-top: 30px;
  }
  .index-page .l-container-body .sex-list .item:not(:first-child) {
    margin-top: 40px;
  }
  .index-page .l-container-body .sex-list .link {
    font-size: 67px;
    line-height: 1;
    transition: opacity .3s ease;
  }
  .index-page .l-container-body .sex-list .link:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .index-page .l-container-body .sex-list {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;
  }
  .index-page .l-container-body .sex-list .item {
    display: flex;
    flex-direction: column;
    width: 30px;
  }
  .index-page .l-container-body .sex-list .item:nth-child(1) {
    padding-bottom: 20px;
  }
  .index-page .l-container-body .sex-list .item:nth-child(2) {
    position: relative;
    padding-top: 20px;
  }
  .index-page .l-container-body .sex-list .item:nth-child(2)::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
  }
  .index-page .l-container-body .sex-list .item .link {
    font-size: 30px;
    writing-mode: vertical-rl;
    line-height: 1;
    font-weight: 400;
  }
}

.list-page {
  padding-top: 35px;
}

@media screen and (max-width: 767px) {
  .list-page {
    padding-top: 25px;
  }
}

.list-page .l-container-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-header {
    align-items: flex-start;
    padding-bottom: 35px;
    position: relative;
  }
}

.list-page .l-container-header .logo {
  width: 102px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-header .logo {
    width: 62px;
    position: absolute;
    top: 0;
    left: 6.66667%;
  }
}

.list-page .l-container-header .logo img {
  width: 100%;
}

.list-page .l-container-header .select-list {
  display: flex;
  justify-content: center;
  margin-top: 95px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-header .select-list {
    width: 100%;
  }
}

.list-page .l-container-header .select-list .select-list-item {
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  position: relative;
  font-weight: 300;
}

.list-page .l-container-header .select-list .select-list-item::before {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: calc(3px + 100%);
  transition: width .3s ease;
}

.list-page .l-container-header .select-list .select-list-item:not(:first-child) {
  margin-left: 70px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-header .select-list .select-list-item:not(:first-child) {
    margin-left: 25px;
  }
}

.list-page .l-container-header .select-list .select-list-item.is-active {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .list-page .l-container-header .select-list .select-list-item:hover::before {
    width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.list-page .l-container-body .model-list {
  display: none;
  flex-wrap: wrap;
  max-width: 955px;
  margin: 0 auto;
  opacity: 0;
}

.list-page .l-container-body .model-list.is-active {
  display: flex;
  animation: fadeIn .5s ease .1s forwards;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.list-page .l-container-body .model-list .model-list-item {
  width: 23.03665%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.list-page .l-container-body .model-list .model-list-item::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 147.72727%;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item::before {
    padding-top: 141.93548%;
  }
}

@media screen and (min-width: 768px) {
  .list-page .l-container-body .model-list .model-list-item:not(:nth-child(4n+1)) {
    margin-left: 2.6178%;
  }
  .list-page .l-container-body .model-list .model-list-item:not(:nth-child(-n+4)) {
    margin-top: 2.6178%;
  }
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item {
    width: 47.82609%;
  }
  .list-page .l-container-body .model-list .model-list-item:not(:nth-child(2n+1)) {
    margin-left: 4.34783%;
  }
  .list-page .l-container-body .model-list .model-list-item:not(:nth-child(-n+2)) {
    margin-top: 4.34783%;
  }
}

.list-page .l-container-body .model-list .model-list-item .new {
  font-size: 12px;
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .new {
    font-size: 10px;
  }
}

.list-page .l-container-body .model-list .model-list-item .new::before {
  content: '';
  display: block;
  width: 53px;
  height: 1px;
  background: #000;
  position: absolute;
  left: -5px;
  bottom: -3px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .new::before {
    width: 38px;
  }
}

.list-page .l-container-body .model-list .model-list-item .link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .list-page .l-container-body .model-list .model-list-item .link:hover .black::before {
    left: -15%;
    width: 140%;
  }
  .list-page .l-container-body .model-list .model-list-item .link:hover .text-wrapper {
    transition-delay: .2s;
    opacity: 1;
  }
  .list-page .l-container-body .model-list .model-list-item .link:hover .plus-mark {
    transition-delay: .2s;
    opacity: 1;
  }
  .list-page .l-container-body .model-list .model-list-item .link:hover .not-in-town {
    transition-delay: .2s;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .link.is-hover .black::before {
    left: -15%;
    width: 140%;
  }
  .list-page .l-container-body .model-list .model-list-item .link.is-hover .text-wrapper {
    transition-delay: .2s;
    opacity: 1;
  }
  .list-page .l-container-body .model-list .model-list-item .link.is-hover .plus-mark {
    transition-delay: .2s;
    opacity: 1;
  }
  .list-page .l-container-body .model-list .model-list-item .link.is-hover .not-in-town {
    transition-delay: .2s;
    opacity: 1;
  }
}

.list-page .l-container-body .model-list .model-list-item .black {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.list-page .l-container-body .model-list .model-list-item .black::before {
  position: absolute;
  transition: .3s;
  content: '';
  width: 0;
  left: -15%;
  bottom: 0;
  height: 120%;
  background: rgba(0, 0, 0, 0.85);
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 6.81818%;
  opacity: 0;
  transition: opacity .3s ease;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .text-wrapper {
    padding: 9.67742%;
  }
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .name {
  font-size: 25px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .text-wrapper .name {
    font-size: 15px;
    margin-bottom: 15px;
  }
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list {
  width: 100%;
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list .info-list-item {
  font-size: 10px;
  display: flex;
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list .info-list-item:not(:first-child) {
  margin-top: 6px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list .info-list-item:not(:first-child) {
    margin-top: 5px;
  }
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list .info-list-item .info-name {
  font-weight: 400;
}

.list-page .l-container-body .model-list .model-list-item .text-wrapper .info-list .info-list-item .data {
  font-weight: 600;
  display: block;
  margin-left: 2.27273%;
}

.list-page .l-container-body .model-list .model-list-item .not-in-town {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .3s ease;
}

.list-page .l-container-body .model-list .model-list-item .not-in-town .text {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #fff;
}

.list-page .l-container-body .model-list .model-list-item .not-in-town .text .font-small {
  display: block;
  font-size: 10px;
  font-weight: 300;
  line-height: 1;
  padding-bottom: 4px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .not-in-town .text .font-small {
    font-size: 8px;
  }
}

.list-page .l-container-body .model-list .model-list-item .not-in-town .text .font-large {
  font-weight: bold;
  display: block;
  font-size: 15px;
  font-weight: normal;
  margin-left: 10px;
  line-height: 1;
  padding-bottom: 2px;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .not-in-town .text .font-large {
    font-size: 12px;
  }
}

.list-page .l-container-body .model-list .model-list-item .plus-mark {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6.81818%;
  bottom: 4.61538%;
  opacity: 0;
  transition: opacity .3s ease;
}

@media screen and (max-width: 767px) {
  .list-page .l-container-body .model-list .model-list-item .plus-mark {
    width: 15px;
    height: 15px;
    right: 9.67742%;
    bottom: 9.67742%;
  }
}

.list-page .l-container-body .model-list .model-list-item .plus-mark::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  margin: auto;
}

.list-page .l-container-body .model-list .model-list-item .plus-mark::after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  margin: auto;
}

@keyframes move {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.opening {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.opening.is-active .logo {
  opacity: 1;
  transition: opacity 1s ease;
}

.opening.is-active .border-frame .border {
  animation: move 1s ease-in-out forwards 1s;
}

.opening.is-active .bg {
  transform: skewX(20deg) translateX(140%);
}

.opening .bg {
  position: absolute;
  left: -20vw;
  top: 0;
  width: 140vw;
  height: 100%;
  background: #000;
  z-index: 1;
  transform: skewX(20deg);
  transition: transform 1s ease 2s;
}

@media screen and (max-width: 767px) {
  .opening .bg {
    left: -40vw;
    width: 180vw;
  }
}

.opening .logo {
  opacity: 0;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .opening .logo {
    margin: 0 auto;
  }
  .opening .logo svg {
    width: 100%;
    height: auto;
  }
}

.opening .border-frame {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: translateY(70px);
  z-index: 2;
}

.opening .border-frame .border {
  width: 100%;
  height: 1px;
  background: #fff;
  transform: translateX(-100%);
}
