.footer {
	padding-top: 75px;
	padding-bottom: 40px;
	@include SP {
		padding-top: 65px;
		padding-bottom: 20px;
	}
	.footer-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: 400;
		.copyright {
			font-size: 10px;
			text-align: center;
		}
	}
	.footer-list {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		font-size: 10px;
		.footer-list-item {
			line-height: 1;
			&:not(:first-child) {
				margin-left: 13px;
				position: relative;
				&::before {
					content: '';
					display: block;
					width: 1px;
					height: 8px;
					position: absolute;
					right: calc(100% + 6px);
					top: 0;
					bottom: 0;
					margin: auto;
					background: #000;
				}
			}
		}

	}
}
