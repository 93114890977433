
.index-page {

	.l-container {
		@include PC {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100vh;
		}
		@include SP {
			padding-top: (65 / 375 * 100 + 0%);
		}
	}

	.l-container-body {
		width: 100%;
		display: flex;
		justify-content: center;
		@include PC {
			padding-left: (125 / 1000 * 100 + 0%);
			padding-right: (125 / 1000 * 100 + 0%);
		}
		@include SP {

			height: calc(100vh - 80px);
			_::-webkit-full-page-media, _:future, :root & {
				height: -webkit-fill-available
			}
			position: relative;
		}
		.logo {
			@include SP {
				svg {
					width: 100%;
				}
			}
		}
		.sex-list {
			@include PC {
				margin-left: (78 / 1000 * 100 + 0%);
				.item {
					margin-top:30px;
					&:not(:first-child) {
						margin-top: 40px;
					}
				}

				.link {
					font-size: 67px;
					line-height: 1;
					transition: opacity .3s ease;
					&:hover {
						opacity: 0.7;
					}
				}
			}
			@include SP {
				position: absolute;
				right: 20px;
				bottom: 20px;
				z-index: 10;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 30px;
				.item {
					display: flex;
					flex-direction: column;
					width: 30px;
					&:nth-child(1) {
						padding-bottom: 20px;
					}
					&:nth-child(2) {
						position: relative;
						padding-top: 20px;
						&::before {
							content: '';
							display: block;
							width: 100%;
							height: 1px;
							background: #000;
							position: absolute;
							left: 0;
							top: 0;
						}
					}
					.link {
						font-size: 30px;
						writing-mode: vertical-rl;
						line-height: 1;
						font-weight: 400;
					}
				}
			}
		}
	}
}
