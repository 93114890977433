.header {
	@include PC {
		width: 300px;
		height: 100vh;
		position: fixed;
		right: 0;
		top: 0;
		background: #fff;
		transform: translateX(190px);
		transition: transform .5s ease;
		z-index: 10;
	}
	&.is-hover {
		@include PC {
			transform: translateX(0);
			.toggle {
				.border {
					&::before {
						bottom: 0;
						transform: rotate(45deg);
					}
					&::after {
						top: 0;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	&.is-active {


		@include SP {
			.toggle {
				.border {
					&::before {
						bottom: 0;
						transform: rotate(45deg);
					}
					&::after {
						top: 0;
						transform: rotate(-45deg);
					}
				}
			}
			.global-nav {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.toggle {
		@include PC {
			width: 110px;
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		@include SP {
			width: 25px;
			height: 25px;
			position: absolute;
			right: (25 / 375 * 100 + 0%);
			top: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 101;
			pointer-events: auto;
		}

		.border {
			height: 16px;
			position: relative;
			@include PC {
				width: 30px;
			}
			@include SP {
				width: 25px;
			}
			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #000;
				position: absolute;
				left: 0;
				top: 0;
				transition: transform .5s ease;
			}
			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #000;
				position: absolute;
				left: 0;
				bottom: 0;
				transition: transform .5s ease;
			}
		}


	}

	.global-nav-wrapper {
		@include SP {
			width: 100%;
			height: 100%;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 100;
			pointer-events: none;
		}
	}

	.global-nav {
		@include PC {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			padding-right: 30px;
			width: 190px;
		}
		@include SP {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content:center;
			padding-right: (45 / 375 * 100 + 0%);
			background: #fff;
			transition: opacity .5s ease, visibility .5s ease;
			pointer-events: auto;
		}
		.global-nav-item {
			&:not(:first-child) {
				margin-top: 25px;
			}
		}

		.link {
			font-weight: 400;
			font-size: 16px;
			line-height: 1;

			span {
				position: relative;
				&::before {
					content: '';
					display: block;
					width: 0;
					height: 1px;
					background: #000;
					position: absolute;
					left: 0;
					top: 100%;
					transition: width .3s ease;
				}
			}

			@include PC {
				&:hover {
					span {
						&::before {
							width: 100%;
						}
					}
				}
			}
			@include SP {
				font-size: 27px;
			}
		}

	}
}
