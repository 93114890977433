.g-modal-frame {
	opacity: 0;
	visibility: hidden;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2001;
	overflow: auto;
	transition:  opacity .3s ease, visibility .3s ease;
	pointer-events: none;
	&.active {
		opacity: 1;
		visibility: visible;
	}

	.g-modal-table {
		display: block;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	.g-modal-cell {
		display: block;
		width: 100%;
		height: 100%;
	}

	.g-modal {
		pointer-events: none;
		padding-left: 30px;
		padding-right: 30px;
		height: 100%;
		@include SP {

			padding-left: (20 / 375 * 100 + 0%);
			padding-right: (20 / 375 * 100 + 0%);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& >.inner {
			pointer-events: auto;
			max-width: 1000px;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			z-index: 2;
			padding: 10px 0;
			position: relative;
			border-radius: 5px;
			padding-left: 40px;
			padding-right: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include SP {
				padding: 10px 0;
			}

			img {
				object-fit: cover;
				@include PC {
					max-height: calc(100vh - 20px);
				}
			}
			// .wrap {
			// 	width: 100%;
			// 	position: relative;
			// 	padding-top: (360 / 640 * 100%);
			// 	min-height: 360px;
			// }
			// iframe {
			// 	position: absolute;
			// 	left: 0;
			// 	top: 0;
			// 	width: 100%;
			// 	height: 100%;
			// }
		}
	}

	.close-btn {
		width: 25px;
		height: 25px;
		position: absolute;
		right: 30px;
		top: 30px;
		cursor: pointer;
		pointer-events: auto;
		z-index: 10;
		transition: transform .5s ease;
		@include PC {
			&:hover {
				transform: rotate(90deg);
			}
		}
		.close-icon {
			display: block;
			position: relative;
			width: 25px;
			height: 25px;
			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #fff;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(45deg);
			}
			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #fff;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(-45deg);
			}
		}
	}

	.overlay {
		pointer-events: auto;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(25, 25, 28, 0.85);
		z-index: 1;
	}

	&.modal-image {
		.inner {
			display: flex;
			justify-content: center;
		}
	}

	&.modal-youtube {
		display: none;

		.modal-youtube-inner {
			position: relative;
			max-width: 900px;
			width: 100%;
			margin: 0 auto;
			pointer-events: auto;
			position: relative;
			z-index: 2;
			&::before {
				content: '';
				display: block;
				width: 100%;
				padding-top: (500 / 900 * 100 + 0%);
			}
		}



	}
}
