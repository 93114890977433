@keyframes move {
	0% {
		transform: translateX(-100%);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

.opening {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;


	&.is-active {
		.logo {
			opacity: 1;
			transition: opacity 1s ease;
		}
		.border-frame {
			.border {
				animation: move 1s ease-in-out forwards 1s;
			}
		}
		.bg {
			transform: skewX(20deg) translateX(140%);
		}
	}

	.bg {
		position: absolute;
		left: -20vw;
		top: 0;
		width: 140vw;
		height: 100%;
		background: #000;
		z-index: 1;
		transform: skewX(20deg);
		transition: transform 1s ease 2s;
		@include SP {
			left: -40vw;
			width: 180vw;
		}
	}

	.logo {
		opacity: 0;
		position: relative;
		z-index: 2;
		@include SP {
			margin: 0 auto;

			svg {
				width: 100%;
				height: auto;
			}
		}
	}


	.border-frame {
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		transform: translateY(70px);
		z-index: 2;
		.border {
			width: 100%;
			height: 1px;
			background: #fff;
			transform: translateX(-100%);
		}
	}
}
