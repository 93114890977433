
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}
a,a:hover,a:visited,a:active,a:focus,a:focus-within {
	color: inherit;
}

ul {
  list-style: none;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

main {
	display: block;//IE対策
}

@font-face {
  font-family: 'jost';
  src: url('./font/Jost-400-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'jost';
	src: url('./font/Jost-300-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'jost';
	src: url('./font/Jost-600-Semi.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
  font-family: 'jost';
  src: url('./font/Jost-700-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family:'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  font-weight: 400;
  color: #333;
  word-wrap : break-word;
  overflow-wrap : break-word;
  word-break: break-all;
  @include IE {
  	font-family: 'ＭＳ Ｐゴシック',sans-serif;
  }
}

.site-frame {
	overflow-x: hidden;
	opacity: 0;
	&.active {
		opacity: 1;
		transition: opacity .5s ease-in-out;
	}
}
.jost {
	font-family: 'jost';
}

.bask {
	font-family: 'Libre Baskerville', serif;
}
