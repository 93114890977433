
$goldColor: #a49253;

$breakpoint: 767px;
$max_width: 1000px;
$min_width: 1366px;

//------------------------------------------------
// メディアクエリ
//------------------------------------------------

@mixin CUSTOM_MAX($condition: $max_width) {
  @media screen and (max-width: $condition) {
    @content;
  }
}

@mixin CUSTOM_MIN($condition: $min_width) {
  @media screen and (min-width: $condition) {
    @content;
  }
}



@mixin PC() {
  @media screen and (min-width: $breakpoint + 1) {
    @content;
  }
}



@mixin tablet() {
   @media screen and (max-width: 1079px) {
    @content;
  }
}

@mixin SP() {
  @media screen and (max-width:$breakpoint) {
    @content;
  }
}

@mixin SSP() {
  @media screen and (max-width:320px) {
    @content;
  }
}

@mixin IE() {
	@media all and (-ms-high-contrast:none) {
		@content;
	}
}


@mixin basicButton($border-color,$background-color,$text-color,$text-hover-color) {
	position: relative;
	z-index: 2;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $border-color;
	color: $text-color;
	transition: color .5s ease;
	&::after {
		content: '';
	    display: block;
	    width: 100%;
	    background: $background-color;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom:0;
	    z-index: -1;
	    transition: transform .5s ease;
	    transform-origin: right top;
	    transform: scale(0,1);
	    @include SP {
	    	display: none;
	    }
	}
	@include min-width(751px) {
		&:hover {
			color: $text-hover-color;
			&::after {
	        	transform-origin: left top;
	        	transform: scale(1,1);
			}
		}
	}
}





@mixin Hover {
  transition: opacity .1s ease;
  opacity: 1;
  &:hover{
    @include PC {
      cursor: pointer;
      opacity: .7;
    }
  }
}


@mixin placeholder {
    &:placeholder-shown {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        opacity: 1;
        @content;
    }
    &::-moz-placeholder {
        opacity: 1;
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &:focus{
      text-align: left;
      &::placeholder{
        color: transparent;
      }
    }
}

/**
 * フォントサイズの可変
 *
 * @param {number} $fontsize 基準となるフォントサイズ
 * @param {number} $basewidth 基準となる幅
 * @param {number} $maxwidth フォントサイズの拡大をとめる幅（未指定時は上限なし）
 * @param {number} $minwidth フォントサイズの縮小をとめる幅（未指定時は下限なし）
 */
 @mixin fontResize ($fontsize, $basewidth, $maxwidth:0, $minwidth:0) {
	font-size: ($fontsize * (1000 / $basewidth) / 10) + 0vw;

	@if $maxwidth != 0 {
		@media screen and (min-width:#{$maxwidth}px) {
			font-size: $fontsize * ($maxwidth / $basewidth) + 0px;
		}
	}
	@if $minwidth != 0 {
		@media screen and (max-width:#{$minwidth}px) {
			font-size: $fontsize * ($minwidth / $basewidth) + 0px;
		}
	}
}

@mixin Font($maxWidth, $minWidth, $maxFontSize ,$minFontSize) {
  $font_Size_Difference: $maxFontSize - $minFontSize;
  $viewport_Width_Difference: $maxWidth - $minWidth;
  $XX: $minWidth / 100;
  $YY: 100 * $font_Size_Difference / $viewport_Width_Difference;
  $ZZ: $minFontSize / 16;
  @media screen and (min-width:#{$minWidth}px){
    & {
      font-size: calc(#{$ZZ}rem + ((1vw - #{$XX}px) * #{$YY}));
    }
  }
  @media screen and (min-width:#{$maxWidth}px){
    & {
      font-size: #{$maxFontSize}px;
    }
  }
  @media screen and (max-width:#{$minWidth - 1}px){
    & {
      font-size: #{$minFontSize}px;
    }
  }
}

@mixin EN ($type: none){

	@if $type == 'PC'  {

		@media screen and (min-width: $breakpoint + 1) {

			.en & {
				@content;
			}
		}
	}
	@else if $type == 'SP' {

		@media screen and (max-width:$breakpoint) {

			.en & {
				@content;
			}
		}

	}
	@else {
		.en & {
			@content;
		}
	}

}
